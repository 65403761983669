import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonTickSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'tick small icon'}</title>
      <path d='M2,9.014L3.414,7.6L6.004,10.189L12.593,3.6L14.007,5.014L6.003,13.017L2,9.014Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
