import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { glsp, themeVal, visuallyHidden } from '@devseed-ui/theme-provider';

const pulse = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const pulsingAnimation = css`
  animation: ${pulse} 0.8s ease 0s infinite alternate;
`;

const PageLoadingSelf = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${glsp()};
  height: 100vh;
  padding: ${glsp()};
  background: ${themeVal('color.surface')};

  > div {
    background: ${themeVal('color.base-100')};
    ${pulsingAnimation}
  }

  > div:nth-child(1) {
    height: 10%;
  }

  > div:nth-child(2) {
    height: 25%;
  }

  > div:nth-child(3) {
    flex-grow: 1;
  }

  p {
    ${visuallyHidden()}
  }
`;

export function PageLoading() {
  return (
    <PageLoadingSelf>
      <div />
      <div />
      <div />
      <p>Loading page...</p>
    </PageLoadingSelf>
  );
}
