import React from 'react';
import T from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';

const sizeMatrix = {
  small: 12,
  medium: 16,
  large: 24,
  xlarge: 32,
  xxlarge: 48
};

/**
 * Creates a collecticons component by passing default props to attach the
 * SVG.
 *
 * @param {function} creatorFn Function to create the collecticon. An SVG
 * should be returned.
 */
export function createCollecticon(creatorFn) {
  const StyledCollecticon = React.forwardRef((props, ref) => {
    const { color, size, meaningful, ...rest } = props;

    const s = (typeof size === 'string' ? sizeMatrix[size] : size) || 16;
    const iconProps = {
      width: s,
      height: s,
      fill: color || 'currentColor',
      xmlns: 'http://www.w3.org/2000/svg',
      role: 'img',
      viewBox: '0 0 16 16',
      // By default icons are rendered decoratively (aria hidden true) Using
      // "meaningful" prop (together with a descriptive title) will enable the
      // icon to be understood by assistive technologies.
      'aria-hidden': !meaningful,
      ref,
      ...rest
    };
    return creatorFn(iconProps);
  });

  StyledCollecticon.displayName = 'StyledCollecticon';
  StyledCollecticon.propTypes = {
    color: T.string,
    size: T.oneOfType([
      T.oneOf(['small', 'medium', 'large', 'xlarge', 'xxlarge']),
      T.number
    ]),
    meaningful: T.bool
  };

  return StyledCollecticon;
}

/**
 * Creates a data URI of the given icon for use as image src.
 *
 * @param Cmp Component of the icon to render
 * @param props Props for the component
 */
export const iconDataURI = (Cmp, props = {}) => {
  const str = renderToStaticMarkup(<Cmp {...props} />);
  return `data:image/svg+xml,${encodeURIComponent(str)}`;
};
