import styled, { createGlobalStyle } from 'styled-components';

import { themeVal, antialiased, glsp, rgba } from '@devseed-ui/theme-provider';
import { rotateCW } from '@devseed-ui/animation';

// Block the body scroll when the loading is visible
export const BodyUnscrollable = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

export const Spinner = styled.div`
  display: flex;
  animation: ${rotateCW} 1s linear infinite;
  transform: translateZ(0);
`;

export const GlobalLoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9997;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  cursor: not-allowed;
  background: radial-gradient(
    farthest-side,
    ${themeVal('color.surface')},
    ${rgba(themeVal('color.surface'), 0.64)}
  );

  &.overlay-loader-enter {
    transform: translate3d(0, 0, 0);
    transition: opacity 0.32s ease 0s, visibility 0.32s linear 0s;
    opacity: 0;
    visibility: hidden;

    &.overlay-loader-enter-active {
      opacity: 1;
      visibility: visible;
    }
  }

  &.overlay-loader-exit {
    transition: opacity 0.32s ease 0s, visibility 0.32s linear 0s;
    opacity: 1;
    visibility: visible;

    &.overlay-loader-exit-active {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const Message = styled.div`
  ${antialiased()}
  margin-top: ${glsp()};
  color: ${themeVal('color.base')};
  text-align: center;
`;
