import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronDownSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron down small icon'}</title>
      <polygon points='12.586,4.586 8,9.172 3.414,4.586 2,6 8,12 14,6' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
