import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonHamburgerMenu = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'hamburger menu icon'}</title>
      <path d='M1,9h14V7H1V9z M1,14h14v-2H1V14z M1,2v2h14V2H1z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
