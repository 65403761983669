import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronDown = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron down icon'}</title>
      <polygon points='14.586,3.586 8,10.172 1.414,3.586 0,5 8,13 16,5' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
