import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonArrowRight = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'arrow right icon'}</title>
      <polygon points='7.586,2.414 12.172,7 0,7 0,9 12.172,9 7.586,13.586 9,15 16,8 9,1' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
